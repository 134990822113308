<template>
    <v-container class="pa-16" id="sectionsCardsContainer">
        <!-- CARDS ROW -->
        <v-row class="pa-0 mb-4">
            <!-- SECTION CARD COLUMN -->
            <v-col v-for="(card, index) in cardsList" :key="index" lg="3" md="6" sm="6" xl="3" xs="6">
                <v-hover v-slot="{ hover }">
                    <router-link :to="card.route">
                        <!-- SECTION CARD -->
                        <v-container
                            :style="card.position === true ? hover ? 'margin-top: 4vh;' : 'margin-top: 5vh;' : hover ? 'margin-top: -1vh;' : ''"
                            class="pa-0 sectionCard"
                            fluid
                            style="height: 70vh; width: 100%;"
                        >
                            <v-col align="center" align-self="center" class="pa-0">
                                <!-- CARD TITLE -->
                                <p
                                    :style="hover ? 'color:  #e6413d;' : 'color: #3D3533;'"
                                    class="centuryGothicBold cardTitle"
                                >{{ card.title.toUpperCase() }}</p>

                                <!-- CARD IMAGE -->
                                <v-img
                                    :gradient="hover ? redGradient : blackGradient"
                                    :src="require(`../../assets/mb/${card.imgName}`)"
                                    class="mt-2 leftCardImage"
                                    height="35vh"
                                ></v-img>

                                <!-- CARD DESCRIPTION -->
                                <v-container :class="hover ? 'redBackground' : 'blackBackground'">
                                    <p class="centuryGothic white--text mb-n6">
                                        {{ card.description }}
                                    </p>
                                </v-container>

                                <!-- CARD BUTTON -->
                                <v-container
                                    :class="hover ? 'redBackground' : 'blackBackground'"
                                    style="border-bottom-left-radius: 150px; border-bottom-right-radius: 150px;"
                                >
                                    <v-btn
                                        :color="hover ? 'secondary' : 'secondary'"
                                        class="mt-4 mb-16"
                                        elevation="0"
                                    >Ver más
                                    </v-btn>
                                </v-container>
                            </v-col>
                        </v-container>
                    </router-link>
                </v-hover>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "SectionsCards_Section",
    data() {
        return {
            blackGradient: 'to bottom, rgba(61, 53, 51,.01), rgba(61, 53, 51,.1), rgba(61, 53, 51,.2), rgba(61, 53, 51,1)',
            redGradient: 'to bottom, rgba(230, 65, 61,.01), rgba(230, 65, 61,.1), rgba(230, 65, 61,.2), rgba(230, 65, 61,1)',
            cardsList: [
                {
                    position: true,
                    title: 'Nosotros',
                    route: '/aboutUs',
                    imgName: 'nosotros_somos_mezcla_brava.png',
                    description: 'Mejoramos el proceso tradicional para realizar una mezcla, sólo hay que agregarle agua y está lista.'
                },
                {
                    position: false,
                    title: 'SCMB',
                    route: '/scmb',
                    imgName: 'SCMB.jpg',
                    description: 'Conoce cómo nuestro equipo de especialistas te brinda apoyo técnico y logístico en cualquier etapa de tu construcción.'
                },
                {
                    position: true,
                    title: 'Distribuidores',
                    route: '/dealers',
                    imgName: 'Dealers.jpeg',
                    description: 'Encuentra aquí a tu distribuidor más cercano, quien con gusto te puede asesorar.'
                },
                {
                    position: false,
                    title: 'Tutoriales',
                    route: '/academy',
                    imgName: 'tutoriales_para_preparar_mezcla brava.png',
                    description: 'Aprende características de producto, modo de preparación y aplicación en nuestros tutoriales de productos y maquinarias.'
                }
            ],
        }
    }
}
</script>

<style scoped>
.blackBackground {
    background-color: #3D3533;
}

.redBackground {
    background-color: #e6413d;
}

.cardTitle {
    color: #3D3533;
    font-size: 1.6em;
    margin: 0 !important;
}

a {
    text-decoration: none !important;
}

@media (max-width: 960px) {
    #sectionsCardsContainer {
        padding: 30px 60px 0 60px !important;
    }

    .sectionCard {
        margin-top: 0 !important;
        margin-bottom: 8vh;
    }
}
</style>