<template>
    <v-container class="pa-0" fluid style="margin-top: 74px;">
        <v-col class="pa-0">
            <!-- JUMBOTRON -->
            <div class="jumbotronVideoContainer">
                <video
                    muted
                    autoplay
                    loop
                    playsinline
                    style="margin-top: -22px !important; width: 100%;"
                >
                    <source v-if="this.breakpointName === 'xs' || this.breakpointName === 'sm'" src="../../assets/video/Jumbotron_Mobile.mp4" type="video/mp4">
                    <source v-else src="../../assets/video/Jumbotron_Desktop.mp4" type="video/mp4">
                </video>

                <v-row justify="center" align="center" class="hidden-sm-and-down">
                    <v-icon x-large color="white" class="bouncingIcon" style="margin-top: -50px;">mdi-chevron-down</v-icon>
                </v-row>
            </div>

            <!-- COMPANY INTRO -->
            <v-row align="center" class="mt-12 pa-0 ma-0" justify="center">
                <v-col align="center" class="pa-0 ma-0">
                    <v-row align="center" justify="center" style="width: 100%;" class="pa-0 ma-0 px-4">
                        <p class="centuryGothicBold screenTitle" style="color: #e6413d !important;">
                            OFRECEMOS SOLUCIONES PARA TU OBRA
                        </p>
                    </v-row>

                    <v-row align="center" justify="center" style="width: 100%;" class="pa-0 ma-0">
                        <v-col cols="2" class="hidden-sm-and-down"></v-col>
                        <v-col class="px-8" xl="8" lg="8" md="12" sm="12" xs="12">
                            <p class="centuryGothic">
                                MEZCLA BRAVA son mezclas listas para la construcción, sólo agrega agua, mezcla,
                                y está listo para usar.
                            </p>
                            <p class="centuryGothic">
                                A través de la innovación, desarrollamos productos de la más alta calidad para cada
                                etapa de tu obra, puedes RELLENAR, LEVANTAR MUROS, RECUBRIR y PEGAR, con nuestros
                                Micro concretos, Junteos, Estucos, Adhesivos y Nivelantes.
                            </p>
                            <p class="centuryGothic">
                                Mezcla Brava es parte de la gran familia de GRUPO CALIDRA; la empresa con mayor
                                tradición en la industria de la construcción y orgullosamente mexicanos.

                            </p>
                        </v-col>
                        <v-col cols="2" class="hidden-sm-and-down"></v-col>
                    </v-row>

                    <v-row no-gutters align="center" justify="center" style="width: 100%;">
                        <v-col cols="12" md="2"></v-col>
                        <v-col cols="12" md="8">
                            <v-row no-gutters align="center" justify="center">
                                <v-col cols="12" md="8" class="pa-4 pa-md-0">
                                    <v-img
                                        src="../../assets/products/productos_mezcla_brava.png"
                                    ></v-img>
                                </v-col>
                                <v-col cols="4" md="3">
                                    <v-img
                                        src="../../assets/icons/Soluciones_Calidra.svg"
                                    ></v-img>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="2"></v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- PRODUCTS FAMILIES -->
            <ProductFamiliesCards_Section></ProductFamiliesCards_Section>

            <!-- PRODUCTS BENEFITS -->
            <Benefits_Section :color="true"></Benefits_Section>

            <!-- SECTIONS CARDS -->
            <SectionsCards_Section></SectionsCards_Section>

            <!-- SUCCESS STORIES -->
            <SuccessStories_Section></SuccessStories_Section>

            <!-- REGULATIONS SECTION -->
<!--            <Regulations_Section></Regulations_Section>-->

        </v-col>
    </v-container>
</template>

<script>
import ProductFamiliesCards_Section from "../../components/sections/ProductFamiliesCards_Section";
import Benefits_Section from "../../components/sections/Benefits_Section";
import SectionsCards_Section from "../../components/sections/SectionsCards_Section";
import SuccessStories_Section from "../../components/sections/SuccessStories_Section";
// import Regulations_Section from "../components/sections/Regulations_Section";


export default {
    name: 'Home',
    components: {
        ProductFamiliesCards_Section,
        Benefits_Section,
        SectionsCards_Section,
        SuccessStories_Section,
        // Regulations_Section,
    },
    data() {
        return {
            breakpointName: '',
        }
    },
    mounted() {
        this.breakpointName = this.$vuetify.breakpoint.name;
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava',
            meta: [
                { vmid: 'description', name: 'description', content: 'Mezcla Brava es parte de la gran familia de GRUPO CALIDRA; la empresa con mayor tradición en la industria de la construcción y orgullosamente mexicanos.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.familyText {
    color: #231F20;
}

.familyTextHovered {
    color: #e6413d;
}

.bouncingIcon {
    animation: bounce 2s;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-12px);
    }
}

@media (max-width: 960px) {
    .bouncingIcon {
        margin-top: 32vh !important;
        margin-bottom: 5vh !important;
    }
}
</style>
