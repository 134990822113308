<template>
    <v-row align="center" class="pa-0 ma-0 mt-8" justify="center">
        <v-col align="center" align-self="center" class="pa-0 ma-0">
            <!-- SECTION TITLE -->
            <v-row align="center" class="pa-0 ma-0 px-16" justify="center">
                <p class="centuryGothicBold screenTitle mx-0 px-0 mx-md-16 px-md-16" style="color: #e6413d !important;">
                    MEZCLAS LISTAS específicas para cada etapa de la construcción
                </p>
            </v-row>

            <v-row id="familiesRow" align="start" class="pa-0 ma-0" justify="center">
                <!-- FAMILIES BUTTON -->
                <v-col v-for="(family, index) in familiesList" :key="index" class="pa-8 familyButtonColumn" lg="3" md="12" sm="12"
                       xl="3" xs="12">
                    <router-link
                        :to="family.route"
                        style="text-decoration: none !important;"
                    >
                        <v-hover v-slot="{ hover }">
                            <v-container class="pa-0">
                                <v-card
                                    :elevation="hover ? 16 : 3"
                                    class="py-3 rounded-lg familyIconButtonContainer"
                                >
                                    <v-img
                                        :src="require(`../../assets/icons/${family.icon}`)"
                                        class="familyIconButton"
                                        transition="scale-transition"
                                    ></v-img>
                                </v-card>

                                <p
                                    :class="hover ? 'familyTextHovered' : 'familyText'"
                                    class="centuryGothicBold screenTitle mt-10 familyTitleButtonText"
                                >{{ family.title.toUpperCase() }}</p>

                                <p
                                    :class="hover ? 'familyTextHovered' : 'familyText'"
                                    class="centuryGothic mt-n2 mb-8"
                                >{{ family.label }}</p>
                            </v-container>
                        </v-hover>
                    </router-link>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ProductFamiliesCards_Section",
    data() {
        return {
            familiesList: [
                {
                    route: '/family/microconcretos',
                    icon: 'Micro_Concretos.svg',
                    title: 'MICRO CONCRETOS',
                    label: 'Elaboración de elementos estructurales.',
                },
                {
                    route: '/family/junteos',
                    icon: 'Junteos.svg',
                    title: 'JUNTEOS',
                    label: 'Construcción de muros divisorios y de carga.',
                },
                {
                    route: '/family/estucos',
                    icon: 'Estucos.svg',
                    title: 'ESTUCOS Y REPELLO',
                    label: 'Recubrimiento y/o aplanado de muros.',
                },
                {
                    route: '/family/adhesivos',
                    icon: 'Adhesivos_y_Nivelantes.svg',
                    title: 'ADHESIVOS Y NIVELANTES',
                    label: 'Instalación de revestimientos y corrección de desniveles.',
                },
            ]
        }
    },
}
</script>

<style scoped>
.familyText {
    color: #231F20;
}

.familyTextHovered {
    color: #e6413d;
}

#familiesRow {
    padding: 0 10vh !important;
    background: repeating-linear-gradient(
        to bottom,
        #FFF 0px,
        #FFF 12vh,
        #e6413d 0px,
        #e6413d 30vh,
        #FFF 0px,
        #FFF 60vh
    );
}

.familyIconButton {
    height: 21vh;
    width: 21vh !important;
}

.familyIconButtonContainer {
    height: 23vh;
    width: 23vh !important;
}

.familyTitleButtonText {
    font-size: 1.75em;
}

@media (max-width: 960px) {
    #familiesRow {
        background: none;
        padding: 0 40px !important;
    }

    .familyButtonColumn {
        padding: 0 !important;
    }

    .familyTitleButtonText {
        margin-top: 20px !important;
    }
}
</style>